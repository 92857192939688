import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import TelevisionPDP from 'src/components/PDP/TelevisionPDP'
import SEO from '../components/shared/SEO'

export const query = graphql`
  query {
    allProductsJson(filter: { slug: { eq: "tv" } }) {
      edges {
        node {
          slug
          commercialUrl
          copy {
            discountCode
            difference
            purchaseOptions {
              label
              quantity
            }
          }
          images {
            offer {
              desktop {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
              mobile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
              alt
            }
            benefits {
              desktop {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
              mobile {
                childImageSharp {
                  gatsbyImageData(width: 1250)
                }
              }
              alt
            }
            orderNow {
              desktop {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
              alt
            }
          }
          colors {
            productColor
            aboveTheFold {
              bg
              text
            }
            button {
              bg
              text
            }
          }
          seo {
            description
            keywords
            title
          }
        }
      }
    }
  }
`

const Television1Pdp: React.FC<PageProps> = ({ location }) => {
  const { allProductsJson } = useStaticQuery(query)
  const productData = allProductsJson.edges[0].node
  const { seo } = productData

  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        location={location}
      />

      <Layout location={location}>
        <TelevisionPDP data={productData} />
      </Layout>
    </>
  )
}

export default Television1Pdp
